import React from "react";
// Custom components
import Card from "./Card";
import CardHeader from "./CardHeader";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { MapContainer, Marker } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

function Map({data}) {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p="16px" backgroundColor="white" borderRadius="12px" boxShadow="md">
      <CardHeader p="12px 0px 28px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            Nye lokationer
          </Text>
          <Flex align="center">
            <Icon
              as={IoCheckmarkDoneCircleSharp}
              color="teal.300"
              w={4}
              h={4}
              pe="3px"
            />
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              <Text fontWeight="bold" as="span">
                {data && data.length} besøg
              </Text>{" "}
              denne måned.
            </Text>
          </Flex>
        </Flex>
      </CardHeader>
      <MapContainer
        center={[56.2639, 10.5018]}
        zoom={6}
        style={{ height: "80%",minHeight:"400px", width: "100%", borderRadius: "12px" }}
      >
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyAYXObwfBn4s7Fmgwy96ztYPSypI9l-Hmg"
          type={"roadmap"}
        />
          {
            data && data.map(
              (coords)=>{
                return  <Marker position={[coords.lat, coords.long]}></Marker>
              }
            )
          }
      </MapContainer>
    </Card>
  );
}

export default Map;
