import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import "./Dashboard.css"
import React, { useEffect, useRef, useState } from "react";
import MiniStatistics from "./components/MiniStatistics";
import SalesOverview from "./components/SalesOverview";
// Custom icons
import { GlobeIcon } from "./components/Icons";
import LineChart from "./components/LineChart";
import Projects from "./components/Projects";
import Map from "./components/Map";
import { Link } from "react-router-dom";
import axios from "axios";


function Dashboard() {
  const iconBoxInside = useColorModeValue("white", "white");
  const [searchInput,setSearchInput]=useState()
  const [searchResult,setSearchResult]=useState()
  const [currentSaloon,setCurrentSaloon]=useState()
  const [webData,setWebData]=useState()
  const [mobileData,setMobileData]=useState()
  const [clientsTableData,setClientsTableData]=useState()
  const [mapCoords,setMapCoords]=useState()
  const res=useRef()

  const handleGetSaloon=async (sid)=>{
    res.current.value=""
    try {
      const res = await axios.get(`/dashboard/monotorise/details/${sid}`);
      setMobileData(null)
      setWebData(null)
      setChartData(null)
      if (res.status === 200) {
      setCurrentSaloon(res.data)
      setWebData(res.data.business_data.last_year_website_count)
      setMobileData(res.data.business_data.last_year_mobile_count)
      let i=0;
      let table=[]
      let coords=[]
      for (let _ in res.data.business_data.this_month_visits_details){
        table[i]= {
          name: res.data.business_data.this_month_visits_details[i].ip,
          city: res.data.business_data.this_month_visits_details[i].city,
          time: res.data.business_data.this_month_visits_details[i].visit_time,
          date: res.data.business_data.this_month_visits_details[i].visit_date,
        }
        coords[i]={
          long: res.data.business_data.this_month_visits_details[i].longtitude,
          lat: res.data.business_data.this_month_visits_details[i].latitude,
        }
        i++
      }
      setClientsTableData(table)
      setMapCoords(coords)
      setSearchResult(null)
   
      
      }
    } catch (err) {
    
    }

  }
   // Handle setting appointment & saloon & subservice & staff data - On page load
   useEffect(() => {
    window.scrollTo(0, 0);
    if (!searchInput) {
      setSearchResult(null)
    } else {
      const fetchData = async () => {
        try {
          const res = await axios.get(`/dashboard/monotorise/suggest/?search_input=${searchInput}`);
          if (res.status === 200) {
          setSearchResult(res.data['search_suggestions']['external_salons'])
          }
        } catch (err) {
        
        }
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);
  const handleInputChange=()=>{
    setSearchInput(res.current.value)
  }
  const [cData, setChartData] = useState(null);

useEffect(() => {
  // Update chartData when mobileData changes
  setChartData(null)
  if ( mobileData && webData) {
    let hm=<LineChart mobileData={mobileData} webData={webData} />
    setChartData(hm);
  }
}, [currentSaloon]);
  return (
    <Box background="#EDF2F7" minH="100vh" minW="100%">
      <Flex justifyContent="flex-start" mb={3} background="#EDF2F7">
        <Link to="/">
          <Image
            src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
            alt="Logo"
            width={["150px", "200px"]}
            height={["40px", "50px"]}
            m={2}
          />
        </Link>
      </Flex>
      <Flex justifyContent="center">
        <Box width="100%" maxW="900px" p={4} position="relative" mb={5}>
          <Input size="lg" placeholder="Søg..." background="#fff" ref={res} onChange={handleInputChange}/>
          {searchResult &&
      <Box width="100%" maxW="865px" p={4}  mb={5} className="results_of_search"> 
        {searchResult[0]?  searchResult.map((ele)=>{
           return <><div className="saloon_search" key={ele.saloon_id} onClick={()=>handleGetSaloon(ele.saloon_id)}><img className="logo" src={ele.saloon_logo} alt="" /><div className="text"><div>{ele.saloon_name}</div><div className="adress">{ele.saloon_adress}</div></div> </div> <hr/></>
         }):  <div>nothing</div>}
        </Box>}
        </Box>       
      </Flex>
  
      <Flex
        flexDirection="column"
        pt={{ base: "25px", md: "2px" }}
        background="#EDF2F7"
        gap="50px"
      >
        <Heading px={40} isTruncated maxW="1950px">
          Virksomhedsdata: {currentSaloon? currentSaloon.business_data.business_name:"Business Name"}
        </Heading>
        <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="60px" px={40}>
          <MiniStatistics
            title={"Dagens besøg"}
            amount={currentSaloon? currentSaloon.business_data.this_day_growth.today_count:"0,0"}
            percentage={currentSaloon? currentSaloon.business_data.this_day_growth.growth_percentage? currentSaloon.business_data.this_day_growth.growth_percentage:"-100":"0"}
            icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />
          <MiniStatistics
            title={"Denne uges besøg"}
            amount={currentSaloon? currentSaloon.business_data.this_week_growth.current_week_count:"0,0"}
            percentage={currentSaloon? currentSaloon.business_data.this_week_growth.percentage_growth? currentSaloon.business_data.this_week_growth.percentage_growth:"-100":"0"}
            icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />
          <MiniStatistics
            title={"Denne måneds besøg"}
            amount={currentSaloon? currentSaloon.business_data.this_month_growth.current_month_count:"0,0"}
            percentage={currentSaloon? currentSaloon.business_data.this_month_growth.percentage_growth? currentSaloon.business_data.this_month_growth.percentage_growth:"-100":"0"}
         icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />
          <MiniStatistics
            title={"Årets besøg"}
            amount={currentSaloon? currentSaloon.business_data.this_year_growth.current_year_count:"0,0"}
            percentage={currentSaloon? currentSaloon.business_data.this_year_growth.percentage_growth? currentSaloon.business_data.this_year_growth.percentage_growth:"-100":"0"}
     icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />
        </SimpleGrid>
        <Flex minW="100%" flexDirection="column" mb={{ lg: "26px" }} px={40}>
          <SalesOverview
            title={"Besøgsoversigt"}
            percentage={currentSaloon? currentSaloon.business_data.this_year_growth.percentage_growth? currentSaloon.business_data.this_year_growth.percentage_growth:"-100":"0"}
            chart={cData}
          />

        </Flex>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr" }}
          templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
          gap="24px"
          mb={225}
          px={40}
        >
          <Projects
            title={"Nye besøg"}
            amount={clientsTableData? clientsTableData.length:0}
            captions={["IP-adresse", "By", "Tid", "Dato"]}
            data={clientsTableData? clientsTableData: []}
          />
          <Map data={mapCoords} />
        </Grid>
      </Flex>
    </Box>
  );
}

export default Dashboard;
