// Chakra imports
import {
  Flex,
  Icon,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "./Card";
import CardHeader from "./CardHeader";
import DashboardTableRow from "./DashboardTableRow";
import React from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import {MdPictureAsPdf} from "react-icons/md"
import jsPDF from "jspdf";
import "jspdf-autotable";

const Projects = ({ title, amount, captions, data }) => {
  const textColor = useColorModeValue("gray.700", "white");

  // Export data as pdf file
  function exportToPdf() {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        ["IP-adresse", "By", "Tid", "Dato"],
      ],
      body: data.map((row) => [
        row.name,
        row.city,
        row.time,
        row.date
      ]),
    });
    doc.save("table.pdf");
  }
  
  return (
    <Card p="16px" backgroundColor="white" borderRadius="12px" boxShadow="md" >
      <div style={{display:"flex",justifyContent:"space-between"}}>  
      <CardHeader p="12px 0px 28px 0px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold" pb=".5rem">
            {title}
          </Text>
          <Flex align="center">
            <Icon
              as={IoCheckmarkDoneCircleSharp}
              color="teal.300"
              w={4}
              h={4}
              pe="3px"
            />
            <Text fontSize="sm" color="gray.400" fontWeight="normal">
              <Text fontWeight="bold" as="span">
                {amount} besøg
              </Text>{" "}
              denne måned.
            </Text>
          </Flex>
          
        </Flex>
      </CardHeader>
      <button onClick={exportToPdf} my="2" mx="4">
    <MdPictureAsPdf style={{fontSize:"32px"}}/>
  </button></div>
      <div style={{height: '400px', overflowY:'scroll'}}>

      <Table variant="simple" color={textColor} maxH={"400px"} style={{maxHeight: '400px'}}>
        <Thead>
          <Tr my=".8rem" ps="0px">
            {captions.map((caption, idx) => {
              return (
                <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : null}>
                  {caption}
                </Th>
              );
            })}
          </Tr>
        </Thead>

        <Tbody >
          {data.map((row,idx) => {
            return (
              <DashboardTableRow
                key={idx}
                name={row.name}
                city={row.city}
                time={row.time}
                date={row.date}
              />
            );
          })}
        </Tbody>

      </Table>
      </div>

    </Card>
  );
};

export default Projects;
