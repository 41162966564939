import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import App from "./App";
// 1. import `ChakraProvider` component
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App.js";
import axios from "axios";
import Dashboard from "./Dashboard";

axios.defaults.baseURL = "https://api-v3.bookinghero.io/api/";
// axios.defaults.baseURL = "http://localhost:5000/api/";
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
        </Routes>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);
