import React from "react";
import ReactApexChart from "react-apexcharts";

class LineChart extends React.Component {
  constructor({mobileData,webData}) {
    super({mobileData,webData});
    
    this.state = {
      mobileData:  mobileData,
      webData: webData,
      chartData: [],
      chartOptions: {},
    };
  }

 componentDidMount() {
    let mob=[]
    let web=[]
    let cats=[]
     for (let i in this.state.mobileData){
     mob[i]=this.state.mobileData[i]['count']
     cats[i]=this.state.mobileData[i]['month']
    }
    for (let i in this.state.webData){
      web[i]=this.state.webData[i]['count']
     }
     const lineChartOptions = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        theme: "dark",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: cats,
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#c8cfca",
            fontSize: "12px",
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        strokeDashArray: 5,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
          inverseColors: true,
          opacityFrom: 0.8,
          opacityTo: 0,
          stops: [],
        },
        colors: ["#4FD1C5", "#2D3748"],
      },
      colors: ["#4FD1C5", "#2D3748"],
    };
    
    const lineChartData = [
      {
        name: "Mobil app",
        data: mob,
      },
      {
        name: "Hjemmeside",
        data: web,
      },
    ];
    this.setState({
      chartData: lineChartData,
      chartOptions: lineChartOptions,
    });
  }
  
  
  render() {
    return (
      this.state.chartData &&   

      <ReactApexChart
        
        options={this.state.chartOptions}
        series={this.state.chartData &&this.state.chartData}
        type="area"
        width="100%"
        height="100%"
      />
      
    );
  }
}

export default LineChart;
